body {
	background: #404040;
}

.frame {
	max-width: 100vw;
	background: #FFFFFF;
	min-height: 80vh;
	text-align: center;
}

.mframe {
	max-width: 100vw;
	background: #404040;
	color: #FFFFFF;
	min-height: 70vh;
	text-align: center;
	padding-top: 30vh;
}

.cframe {
	min-height: 65vh;
	padding: 1%;
}

.status-bar {
	background: #404040;
	border-top: 6px solid #808080;
	height: 80px;
	font-size: 12px;
	text-align: center;
	color: #FFFFFF;
}

.main-bar {
	background: #F8F8F8;
	color: #000000;
	border-bottom: 1px solid #E0E0E0;
}

.MuiMenu-list {
	border: 1px solid #C0C0C0;
	border-radius: 5px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.fun-panel {
	background: #FFEEBA;
}

.fun-panel:hover {
	background: #FFE8A1;
}